import React from 'react'

const InstIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  style,
  ...props
}) => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    color="#102538"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: 'none', ...style }}
    {...props}
  >
    <rect width="54" height="54" rx="5" fill="white" />
    <g clipPath="url(#clip0_11804_45782)">
      <path
        d="M27.0001 32.3332C28.4146 32.3332 29.7711 31.7713 30.7713 30.7711C31.7715 29.7709 32.3334 28.4143 32.3334 26.9998C32.3334 25.5853 31.7715 24.2288 30.7713 23.2286C29.7711 22.2284 28.4146 21.6665 27.0001 21.6665C25.5856 21.6665 24.229 22.2284 23.2288 23.2286C22.2287 24.2288 21.6667 25.5853 21.6667 26.9998C21.6667 28.4143 22.2287 29.7709 23.2288 30.7711C24.229 31.7713 25.5856 32.3332 27.0001 32.3332Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 32.3333V21.6667C15 19.8986 15.7024 18.2029 16.9526 16.9526C18.2029 15.7024 19.8986 15 21.6667 15H32.3333C34.1014 15 35.7971 15.7024 37.0474 16.9526C38.2976 18.2029 39 19.8986 39 21.6667V32.3333C39 34.1014 38.2976 35.7971 37.0474 37.0474C35.7971 38.2976 34.1014 39 32.3333 39H21.6667C19.8986 39 18.2029 38.2976 16.9526 37.0474C15.7024 35.7971 15 34.1014 15 32.3333Z"
        stroke="currentColor"
        strokeWidth="2.5"
      />
      <path
        d="M34.3333 19.6802L34.3466 19.6655"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11804_45782">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(13 13)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default InstIcon
