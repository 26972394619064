import { useContext } from 'react'
import { LocaleContext } from '../context/LocaleContext'
import getLocalizedPath from '../i18n/get-localized-path'
import { LocalesEnum } from '../types/locales.enum'

export const useTranslations = () => {
  const { t, locale, locales } = useContext(LocaleContext)
  const isDefaultLocale =
    !!locales[locale]?.default || locale === LocalesEnum.Us
  return {
    t: (key = '') => t?.[key] ?? key,
    getPath: (to: string) => getLocalizedPath(to, locale, isDefaultLocale),
    locale,
    locales,
  }
}
