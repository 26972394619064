/* eslint-disable @typescript-eslint/no-redeclare */
export const youtubeLink =
  'https://www.youtube.com/channel/UCys3KnaYRe417IwrMOj7rPQ'
export const youtubeLinkUs = 'https://www.youtube.com/@signax_llc'
export const telegramLink = 'https://t.me/sgnlpro'
export const telegramLinkUs = 'https://t.me/signax'
export const telegramBotLink = 'https://t.me/sgnl_support_bot'
export const linkedinLinkUs = 'https://www.linkedin.com/company/signax-llc/'
export const whatsappLinkUs = 'https://wa.me/971501380516'
export const vkLink = 'https://vk.com/signal_bim'

export const dashboardLink = 'https://dm.sgnl.pro/'
export const dashboardIosLinkSgnl =
  'https://apps.apple.com/ru/app/dashboard-sgnl/id1664784890'
export const dashboardAndroidLinkSgnl =
  'https://play.google.com/store/apps/details?id=com.mobile.dashboard.sgnl'
export const dashboardIosLinkSgnx =
  'https://apps.apple.com/ae/app/dashboard-sgnx/id1664840411'
export const dashboardAndroidLinkSgnx =
  'https://play.google.com/store/apps/details?id=com.mobile.dashboard.sgnx'

export const appSgnl = 'https://hub.sgnl.pro'
export const appSgnx = 'https://hub.signax.io'

export const wikiSgnlConnect = 'https://wiki.sgnl.pro/shelves/2-podklyucenie'
export const wikiSignal = 'https://wiki.sgnl.pro'
export const wikiSignax = 'https://wiki.signax.io'

export const valueLink =
  'https://wiki.sgnl.pro/books/24-podklyucenie-signal-k-bim360/page/podklyucenie-signal-k-bim360'

export const toolsLink = 'https://tools.sgnl.pro/'
export const toolsLinkAe = 'https://tools.signax.io/'

export const inspLink = 'https://photo.sgnl.pro/'
export const inspIOSLink =
  'https://apps.apple.com/ru/app/photomaker-sgnl/id1667758079'
export const inspAndroidLink =
  'https://play.google.com/store/apps/details?id=com.sgnl.photomaker'
export const inspIOSLinkAe =
  'https://apps.apple.com/ru/app/photomaker-sgnx/id1667759800'
export const inspAndroidLinkAe =
  'https://play.google.com/store/apps/details?id=com.sgnx.photomaker'

export const signalMainDownloadPresentationLink =
  'https://drive.google.com/uc?export=download&id=1-nfYvSBox8fVkDAiFdio0_PauC9JlvmI'
export const signalDocsDownloadPresentationLink =
  'https://drive.google.com/uc?export=download&id=1DZwW9P-NXd5Rp8UtoyX7FVd6eK-DJRyZ'
export const signaxDocsDownloadPresentationLink =
  'https://docs.signax.io/s/i/1f95310c-87c6-4ec2-8c94-f6c09970ef24'

export const downloadPriceListLink =
  'https://docs.sgnl.pro/s/i/a0b996c8-86eb-4969-a7f5-b0c3adfdc4e4'

export const autodeskSignaxLink =
  'https://www.autodesk.com/support/partners/signax/2113'

export const DEFAULT_SITE_TITLE = 'Signal'
export const DEFAULT_LOCALE = 'ru'
export const DEFAULT_REGION = 'ru'

export const indexPageIds = {
  signal: 'signal',
  modules: 'modules',
  rates: 'rates',
  feedback: 'feedback',
  howItWorks: 'how-it-works',
  about: 'about',
  requirements: 'requirements',
  video: 'signal-promo-video',
  partners: 'partners',
} as const
