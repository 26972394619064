import React from 'react'
import { Link } from 'gatsby'
import { useTranslations } from '../../hooks/use-translations'

interface IProps {
  className?: string
  activeClass?: string
  path: string
  title: string
  exact?: boolean
}

const PageLink: React.FC<IProps> = ({
  path,
  title,
  className,
  activeClass,
  exact,
}) => {
  const { getPath, t } = useTranslations()

  return (
    <Link
      className={className}
      activeClassName={activeClass}
      to={getPath(path)}
      title={t(title)}
      partiallyActive={!exact}
    >
      {t(title)}
    </Link>
  )
}

export default PageLink
