import React from 'react'
import cn from 'classnames'
import Container from '../Common/Container'

import EmailLink from '../Common/EmailLink'
import PhoneLink from '../Common/PhoneLink'
import PageLink from '../PageLink'
import Link from '../Common/Link'
import SocialLinks from '../social-links'
import TelegramBotLink from '../Common/TelegramBotLink'

import { pages } from './config'
import { AppRegionEnum } from '../../types/app-region.enum'
import { GlobalContext } from '../../context/GlobalContext'
import { useTranslations } from '../../hooks/use-translations'
import { telegramBotLink } from '../../data'

import * as s from './Footer.module.scss'

const Footer: React.FC = () => {
  const { region } = React.useContext(GlobalContext)
  const { t } = useTranslations()

  return (
    <footer className={s.footer}>
      <Container>
        <div className={s.nav_content}>
          <nav className={s.nav}>
            {pages[region as AppRegionEnum].map((data, idx) => (
              <div className={s.nav_wrapper} key={idx}>
                {data.title && (
                  <span className={s.nav_title}>{data.title}</span>
                )}
                <ul>
                  {data.pageArray.map((page, idx) => (
                    <>
                      <li key={page.title + idx} className={s.nav_item}>
                        {page.path &&
                          (page.target ? (
                            <Link
                              href={page.path}
                              target={page.target}
                              rel="nofollow noreferrer"
                              color="white"
                              className={cn(
                                s.nav_link,
                                data.title === t('Products') && 'is-uppercase'
                              )}
                            >
                              {page.title}
                            </Link>
                          ) : (
                            <PageLink
                              className={cn(
                                s.nav_link,
                                data.title === t('Products') && 'is-uppercase'
                              )}
                              {...page}
                            />
                          ))}
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            ))}
          </nav>
          <div>{renderContacts(region as AppRegionEnum)}</div>
        </div>
        <div className={s.info_content}>
          <div>
            <p className={s.text}>{t('address')}</p>
            {region === AppRegionEnum.Ru && (
              <p className={s.text}>
                {`ПО представляет собой интернет-сервис, дополнительных требований\n по установке программы со стороны пользователя не требуется`}
              </p>
            )}
          </div>
          <SocialLinks />
        </div>
        <p style={{ opacity: 0 }}>{process.env.GATSBY_APP_VERSION}</p>
      </Container>
    </footer>
  )
}

export default Footer

const renderContacts = (region: AppRegionEnum) => (
  <>
    <EmailLink />
    <PhoneLink className="mt-2" />
    {region === AppRegionEnum.Ru && (
      <TelegramBotLink href={telegramBotLink} className="mt-2" />
    )}
  </>
)
