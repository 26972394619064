import React from 'react'

const FBIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    color="#102538"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="54" height="54" rx="5" fill="white" />
    <path
      d="M31.6202 17H30.4278C27.1135 17 24.4172 19.4757 24.4172 22.5187V24.8478H22.3799C22.1701 24.8478 22 25.004 22 25.1966V27.9871C22 28.1797 22.1701 28.3359 22.3799 28.3359H24.417V36.8821C24.417 37.0748 24.5872 37.231 24.7969 37.231H27.8362C28.046 37.231 28.2161 37.0748 28.2161 36.8821V28.3359H31.2391C31.4488 28.3359 31.619 28.1797 31.619 27.9871V25.1966C31.619 25.004 31.4488 24.8478 31.2391 24.8478H28.2161V22.5187C28.2161 21.399 29.2082 20.4881 30.4277 20.4881H31.6201C31.8299 20.4881 32 20.3319 32 20.1393V17.3488C32.0002 17.1562 31.83 17 31.6202 17Z"
      fill="currentColor"
    />
  </svg>
)

export default FBIcon
