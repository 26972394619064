import React from 'react'
import { Link, navigate } from 'gatsby'
import cn from 'classnames'

import Navigation from '../Navigation'
import { Button } from '../Common/Button'
import BurgerButton from '../Common/BurgerButton'
import ContactButton from '../Common/ContactButton'
import ContactForm from '../contact-form'

import SignaxLogoMobileBlueBack from '../../svg/signax-logo-mobile-blue-back'
import SignalLogoMobileBlueBack from '../../svg/signal-logo-mobile-blue-back'
import AutodeskLogo from '../../svg/autodesk-logo'

import { useTranslations } from '../../hooks/use-translations'
import { GlobalContext } from '../../context/GlobalContext'
import { AppRegionEnum } from '../../types/app-region.enum'
import {
  appSgnl,
  appSgnx,
  autodeskSignaxLink,
  telegramBotLink,
  telegramLink,
  vkLink,
  whatsappLinkUs,
  youtubeLink,
} from '../../data'
import { toLink } from '../../utils'
import { PathEnum } from '../../types/pages'

import WhatsappBtnIcon from '../../svg/whatsapp-btn-icon'
import PhoneFilledBtnIcon from '../../svg/phone-filled-btn-icon'
import PhoneIcon from '../../svg/phone-icon'
import EmailFilledBtnIcon from '../../svg/email-filled-btn-icon'
import EmailIcon from '../../svg/email-icon'
import TelegramBtnIcon from '../../svg/telegram-btn-icon'
import YoutubeBtnIcon from '../../svg/youtube-btn-icon'
import TelegramBotFilledBtnIcon from '../../svg/telegram-bot-filled-btn-icon'
import VkBtnIcon from '../../svg/vk-btn-icon'

import * as s from './header.module.scss'

const MenuMobile = () => {
  const { getPath, t } = useTranslations()
  const { region, siteTitle, location } = React.useContext(GlobalContext)
  const [menuExpanded, setMenuExpanded] = React.useState(false)
  const [isOpenModal, setIsOpenModal] = React.useState(false)
  const email = t('info_email')
  const phone = t('Phone')

  React.useEffect(() => {
    setMenuExpanded(false)
  }, [location])

  React.useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflowY = menuExpanded
      ? 'hidden'
      : 'scroll'
  }, [menuExpanded])

  return (
    <>
      <div
        className={cn(
          'flex justify-between items-center w-100',
          s.is_hidden_desktop
        )}
      >
        <div>
          {location?.pathname === PathEnum.Home ? (
            renderLogo(region as AppRegionEnum)
          ) : (
            <Link to={getPath(PathEnum.Home)} title={siteTitle}>
              {renderLogo(region as AppRegionEnum)}
            </Link>
          )}
          {region === AppRegionEnum.Us && menuExpanded && (
            <a
              href={autodeskSignaxLink}
              target={'_blank'}
              rel="noreferrer"
              style={{ marginLeft: 18 }}
            >
              <AutodeskLogo width={48} height={41} />
            </a>
          )}
        </div>
        <div
          className={cn(s.menu, {
            [s.active]: menuExpanded,
          })}
        >
          <div>
            <div className={s.btns}>
              <Button
                size="medium"
                color="white"
                className={s.btn_full}
                onClick={() => setIsOpenModal(true)}
              >
                {t('Request demo')}
              </Button>
              <Button
                size="small"
                className={s.btn_login}
                onClick={() => {
                  toLink(region === AppRegionEnum.Ru ? appSgnl : appSgnx)
                }}
              >
                {t(`Login`)}
              </Button>
            </div>
            <Navigation menuExpanded={menuExpanded} />
          </div>
          <div
            className="flex justify-center items-center"
            style={{ gap: region === AppRegionEnum.Ru ? 14 : 16 }}
          >
            {region === AppRegionEnum.Us &&
              location?.pathname !== '/sandbox-access/' && (
                <Button
                  size="medium"
                  color="white"
                  style={{ maxWidth: '120px' }}
                  onClick={() => navigate(getPath('/sandbox-access/'))}
                >
                  Promo
                </Button>
              )}
            {region === AppRegionEnum.Us && (
              <ContactButton href={whatsappLinkUs} Icon={<WhatsappBtnIcon />} />
            )}
            <ContactButton
              type="tel"
              href={phone}
              Icon={
                region === AppRegionEnum.Ru ? (
                  <PhoneFilledBtnIcon />
                ) : (
                  <PhoneIcon />
                )
              }
            />
            <ContactButton
              type="email"
              href={email}
              Icon={
                region === AppRegionEnum.Ru ? (
                  <EmailFilledBtnIcon />
                ) : (
                  <EmailIcon />
                )
              }
            />
            {region === AppRegionEnum.Ru && (
              <>
                <ContactButton
                  href={telegramBotLink}
                  Icon={<TelegramBotFilledBtnIcon />}
                />
                <ContactButton href={youtubeLink} Icon={<YoutubeBtnIcon />} />
                <ContactButton href={telegramLink} Icon={<TelegramBtnIcon />} />
                <ContactButton href={vkLink} Icon={<VkBtnIcon />} />
              </>
            )}
          </div>
        </div>
        <BurgerButton
          menuExpanded={menuExpanded}
          onClick={() => setMenuExpanded(prev => !prev)}
        />
      </div>
      {isOpenModal && (
        <ContactForm
          onClose={() => setIsOpenModal(false)}
          title={t('Request a demo of Signal')}
          requestType="Demo"
        />
      )}
    </>
  )
}

export default MenuMobile

const renderLogo = (region: AppRegionEnum) => {
  return (
    <>
      {region === AppRegionEnum.Ru ? (
        <SignalLogoMobileBlueBack
          style={{ position: 'relative', top: 7, zIndex: 9999 }}
        />
      ) : (
        <SignaxLogoMobileBlueBack
          style={{ position: 'relative', top: 4, zIndex: 9999 }}
        />
      )}
    </>
  )
}
