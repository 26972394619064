import React from 'react'

const WhatsappBtnIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_14971_8744)">
      <path
        d="M12.0117 1.99902C6.50518 1.99902 2.02251 6.47784 2.02051 11.9844C2.01951 13.7445 2.48054 15.4629 3.35462 16.977L1.99902 22.001L7.23196 20.7645C8.6911 21.5606 10.3337 21.9785 12.0059 21.9795H12.0098C17.5153 21.9795 21.996 17.4997 21.999 11.9941C22.001 9.32488 20.963 6.81523 19.0769 4.92704C17.1907 3.03986 14.684 2.00002 12.0117 1.99902ZM12.0098 3.99922C14.146 4.00022 16.1535 4.8331 17.6627 6.34124C19.1718 7.85139 20.0008 9.85798 19.9988 11.9922C19.9968 16.3966 16.4142 19.9793 12.0078 19.9793C10.6747 19.9783 9.35415 19.6436 8.19103 19.0085L7.51714 18.6413L6.77293 18.8171L4.80398 19.282L5.2845 17.4966L5.50132 16.6958L5.08722 15.9769C4.38915 14.7688 4.0197 13.3875 4.0207 11.9844C4.02271 7.58194 7.60634 3.99922 12.0098 3.99922ZM8.47622 7.37455C8.3092 7.37455 8.03916 7.43705 7.81014 7.68708C7.58111 7.9361 6.93505 8.53924 6.93505 9.76736C6.93505 10.9955 7.82967 12.1826 7.95468 12.3496C8.07869 12.5157 9.68152 15.1155 12.2188 16.1156C14.327 16.9467 14.7552 16.7827 15.2132 16.7407C15.6712 16.6997 16.6909 16.1381 16.8989 15.555C17.1069 14.972 17.1074 14.4704 17.0454 14.3674C16.9834 14.2634 16.8169 14.2014 16.5668 14.0764C16.3178 13.9514 15.0906 13.3488 14.8616 13.2657C14.6326 13.1827 14.4651 13.1407 14.2991 13.3908C14.133 13.6408 13.6559 14.2014 13.5099 14.3674C13.3639 14.5344 13.2189 14.5569 12.9688 14.4319C12.7188 14.3059 11.9149 14.0416 10.9608 13.1915C10.2188 12.5305 9.71804 11.7148 9.57203 11.4648C9.42701 11.2158 9.55835 11.079 9.68337 10.955C9.79538 10.843 9.93144 10.6634 10.0564 10.5174C10.1805 10.3714 10.2235 10.2674 10.3065 10.1014C10.3895 9.93536 10.347 9.78885 10.285 9.66383C10.223 9.53882 9.7374 8.30614 9.51538 7.81209C9.32836 7.39705 9.13084 7.38741 8.95283 7.38041C8.80781 7.37441 8.64223 7.37455 8.47622 7.37455Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_14971_8744">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default WhatsappBtnIcon
